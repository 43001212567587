.errorPage > div {
  position: relative;
  z-index: 1;
}

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 820px;
  height: calc(100vh - 356px);
}

.heading {
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 84px;
  color: var(--white);
}

.error {
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 38px;
  text-align: center;
  color: var(--white);
  margin-bottom: 48px;
}

.container {
  text-align: center;
}
